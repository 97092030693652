import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizRulesModal } from '../../../../widgets/wiz-rules-modal';
import { CoinIcon } from '../../../../components/CoinIcon';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../stores/hooks';
import { checkPvPQuizConnectAsync } from '../../../../stores/wiz';
import { useRandomEmoji } from '../../hooks/useRandomEmoji';
import { WizFooterButtons } from './wiz-modal-buttons';
import { setCancelPvPQuiz } from '../../../../api/wiz/wiz';
import { Loader } from '../../../../components/Loader';

interface WizMainWaitProps {
  className?: string;
  onClose?: () => void;
}

export const WizMainWait = ({ className, onClose }: WizMainWaitProps) => {
  const { t } = useTranslation(['translation']);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { gameState, startPvpQuiz } = useAppSelector((state) => state.wiz);
  const { getRandomEmoji } = useRandomEmoji();
  const [randomEmoji, setRandomEmoji] = useState(getRandomEmoji('wait'));
  const { userBids } = useAppSelector((state) => state.wiz);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleRules = () => {
    setIsRulesOpen(!isRulesOpen);
  };

  const handleClose = () => {
    if (startPvpQuiz?.id) {
      setCancelPvPQuiz(startPvpQuiz?.id);
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (gameState === 'wait' && startPvpQuiz?.id) {
      intervalId = setInterval(() => {
        dispatch(checkPvPQuizConnectAsync(startPvpQuiz.id));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [gameState, startPvpQuiz]);

  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 right-0 top-0 z-[60] flex h-screen w-screen flex-col bg-primary-purple-blue-400 p-2',
        className
      )}
    >
      <div
        className="mb-5 flex h-full flex-col gap-4"
        style={{ marginTop: 'calc(var(--safe-area-top-height))' }}
      >
        <div className="mt-10 flex flex-col justify-end gap-4 text-center text-white">
          <h4 className="text-xl font-bold">
            {t('wiz.We_are_waiting_for_the_second_player')}
          </h4>
          {randomEmoji ? (
            <img
              src={randomEmoji}
              className="mx-auto hidden h-[100px] w-[100px] tall:block"
              alt={t('wiz.We_are_waiting_for_the_second_player')}
              onLoad={() => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
            />
          ) : isImageLoading ? (
            <Loader className="mx-auto hidden h-[100px] w-[100px] tall:block" />
          ) : null}
        </div>
        <div className="flex justify-between rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <span>{t('wiz.Bids')}</span>
          <div className="flex flex-col items-end gap-1">
            {userBids.map((bid) => (
              <span key={bid.type}>
                {bid.price}
                <CoinIcon className="h-4 fill-primary-purple-blue-700" />
              </span>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <span>{t('wiz.Game_time')}</span>
          <span>{dayjs.duration(5, 'minutes').humanize()}</span>
        </div>
        <div className="flex items-center justify-between rounded-2xl border border-primary-purple-blue-200 bg-primary-purple-blue-100 p-4 text-sm font-semibold text-primary-purple-blue-700">
          <span>{t('wiz.Number_of_questions')}</span>
          <span>10</span>
        </div>
      </div>
      <WizFooterButtons onRules={handleRules} onClose={handleClose} />
      <WizRulesModal isOpen={isRulesOpen} onClose={handleRules} />
    </div>
  );
};
