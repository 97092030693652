import classNames from 'classnames';
import React from 'react';
import { LoaderMini } from '../../../components/LoaderMini';
import { TColor, TSize, TVariant } from '../model/types/types';
import './MButton.scss';

type Props = {
  color?: TColor;
  variant?: TVariant;
  size?: TSize;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  id?: string;
  loading?: boolean;
  isLoaderCenter?: boolean;
};

export const MButton: React.FC<Props> = ({
  color = 'primary',
  variant = 'default',
  size = 'sm',
  className = '',
  children,
  disabled,
  onClick = () => {},
  id,
  loading,
  isLoaderCenter,
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={classNames(`MButton`, className, variant, color, size, {
        centered: isLoaderCenter,
        disabled,
      })}
    >
      {children}
      {loading && (
        <div className="-ml-2">
          <LoaderMini className="ml-1 max-h-4 max-w-4" />
        </div>
      )}
    </button>
  );
};
