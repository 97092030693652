import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NoAvatar } from '../NoAvatar';
import { CoinIcon } from '../../components/CoinIcon';

interface PvPCompletedCardProps {
  className?: string;
  prizeAmount: number | null;
  createdAt: string;
  opponentAvatar: string | undefined;
  initiatorAvatar: string | undefined;
}

export const PvPCompletedCard = ({
  className,
  prizeAmount,
  createdAt,
  opponentAvatar,
  initiatorAvatar,
}: PvPCompletedCardProps) => {
  const { t } = useTranslation(['translation']);
  const getRelativeTime = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInSeconds < 60) {
      return t('time.second_ago', { count: diffInSeconds });
    }
    if (diffInMinutes < 60) {
      return t('time.minute_ago', { count: diffInMinutes });
    }
    if (diffInHours < 24) {
      return t('time.hour_ago', { count: diffInHours });
    }
    if (diffInDays < 30) {
      return t('time.day_ago', { count: diffInDays });
    }
    return t('time.month_ago', { count: diffInMonths });
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-2 border-t border-secondary-grey-500 pt-2',
        className
      )}
    >
      <div className="flex flex-col justify-center font-semibold">
        <h4 className="gap flex items-center text-base text-secondary-grey-900">
          {Number(prizeAmount)?.toFixed(0)}{' '}
          <CoinIcon className="h-3.5 fill-secondary-grey-900" />
        </h4>
        <span className="text-[10px] text-secondary-dark-grey-500">
          {getRelativeTime(createdAt)}
        </span>
      </div>
      <div className="flex">
        <NoAvatar
          className="relative !h-[32px] !w-[32px] rounded-full"
          src={opponentAvatar}
        />
        <NoAvatar
          className="relative z-10 -ml-2 !h-[32px] !w-[32px] rounded-full"
          src={initiatorAvatar}
        />
      </div>
    </div>
  );
};
